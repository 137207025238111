const firebaseConfig = {
    apiKey: "AIzaSyAJTlUxbEndDBjZBvDJUXGJBelkQHXfNAI",
    authDomain: "for-everyone-2519.firebaseapp.com",
    projectId: "for-everyone-2519",
    storageBucket: "for-everyone-2519.appspot.com",
    messagingSenderId: "221608439000",
    appId: "1:221608439000:web:8d3b9e17733071addbbbad",
    measurementId: "G-3H59FDN20X"
};

export default firebaseConfig;